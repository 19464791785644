import React from 'react';
import { Link } from 'gatsby';
import { SocialIcon } from 'react-social-icons';
import Image from 'gatsby-image';
import SiteContext from '../context/site-context';
import logoType from '../images/logotype.svg';
import { useGraphQL } from '../hooks';

import { Layout, SEO, Hero, ContactForm } from '../components';

export default function Contact() {
  const { isSidebarOpen, setSidebarOpen } = React.useContext(SiteContext);
  const { contactBackground } = useGraphQL();

  return (
    <Layout>
      <SEO title="Contact Us" />

      <header className="relative h-screen bg-gradient">
        <Image
          className="relative w-full h-screen"
          fluid={contactBackground.childImageSharp.fluid}
          alt="4x4 Together logo."
        />
        <div className="absolute inset-0">
          <div className="flex flex-wrap items-center justify-center w-full px-12 pt-4 pb-24 mx-auto pointer-events-auto max-w-screen-xl md:justify-between">
            <Link to="/">
              <img
                className="h-8 md:h-6"
                src={logoType}
                alt="4x4 Together logo."
              />
            </Link>
            <div className="flex items-center justify-center flex-shrink-0 w-full mt-4 md:w-auto">
              <SocialIcon
                style={{ height: `1.5rem`, width: `1.5rem` }}
                url="https://www.facebook.com/4x4together"
                target="_blank"
                rel="noopener noreferrer"
                bgColor="#fff"
              />
              <SocialIcon
                className="ml-2"
                style={{ height: `1.5rem`, width: `1.5rem` }}
                url="https://www.instagram.com/4x4together"
                target="_blank"
                rel="noopener noreferrer"
                bgColor="#fff"
              />
              <SocialIcon
                className="ml-2"
                style={{ height: `1.5rem`, width: `1.5rem` }}
                url="https://twitter.com/4x4together"
                target="_blank"
                rel="noopener noreferrer"
                bgColor="#fff"
              />
              <SocialIcon
                className="ml-2"
                style={{ height: `1.5rem`, width: `1.5rem` }}
                url="https://www.youtube.com/channel/UCsR03-p26KNsjNDFOeszueg"
                target="_blank"
                rel="noopener noreferrer"
                bgColor="#fff"
              />
              <span className="hidden ml-8 text-sm text-white md:block">
                Get in touch
              </span>
              <button
                onClick={() => {
                  setSidebarOpen(!isSidebarOpen);
                }}
                type="button"
                className={`hamburger hamburger--collapse relative z-40 focus:outline-none flex pr-0 ${
                  isSidebarOpen ? ' is-active' : ''
                }`}
                aria-controls="menu"
                aria-expanded="false"
                aria-label="Menu"
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>
            </div>
          </div>
          <div className="pt-12 px-12 mr-6 -mt-24 sm:pt-0 sm:mr-0 sm:px-0 sm:mt-0">
            <ContactForm hasCloseButton={false} />
          </div>
        </div>
      </header>
    </Layout>
  );
}
